// AssignTagFlag.jsx
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useNotify, useRefresh, useListContext, useDataProvider } from "react-admin";
import { API } from "aws-amplify";
import Header from "../utils/auth/getHeader";

// AssignFlag Component
export const AssignFlag = ({ open, handleClose }) => {
    const [flag, setFlag] = useState("");
    const [flags, setFlags] = useState([]);
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const { selectedIds, onUnselectItems } = useListContext();
  
    useEffect(() => {
      setLoading(true);
      dataProvider
        .getList("flag", {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "resource_name" },
          filter: {},
        })
        .then(({ data }) => {
          setFlags(data);
        })
        .catch((error) => {
          notify("Error loading campaigns: " + error.message, "warning");
        })
        .finally(() => {
          setLoading(false);
        });
    }, [dataProvider, notify]);
  
    const handleAssign = async () => {
      try {
        const header = await Header.getHeader();
        await API.post("secure-api", "api/admin/flagassign", {
          headers: {
            ...header,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
          body: {
            user_ids: selectedIds,
            resource_id: flag.id,
          },
        });
        notify("Users updated successfully", "info");
        onUnselectItems();
        refresh();
      } catch (error) {
        notify("Error: Users not updated", "warning");
      }
    };
  
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign Flag</DialogTitle>
        <DialogContent>
          {loading ? (
            <p>Loading flags...</p>
          ) : (
            <FormControl fullWidth>
              <InputLabel id="campaign-select-label">Flag</InputLabel>
              <Select
                labelId="campaign-select-label"
                id="campaign-select"
                value={flag.id}
                onChange={(e) => {
                  const selectedFlag = flags.find((c) => c.id === e.target.value);
                  setFlag(selectedFlag);
                }}
              >
                {flags.map((flagItem) => (
                  <MenuItem key={flagItem.id} value={flagItem.id}>
                    {flagItem.resource_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAssign} color="primary" disabled={loading}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  // AssignTag Component
  export const AssignTag = ({ open, handleClose }) => {
    const [tag, setTag] = useState("");
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const { selectedIds, onUnselectItems } = useListContext();
  
    useEffect(() => {
      setLoading(true);
      dataProvider
        .getList("tag", {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "resource_name" },
          filter: {},
        })
        .then(({ data }) => {
          setTags(data);
        })
        .catch((error) => {
          notify("Error loading campaigns: " + error.message, "warning");
        })
        .finally(() => {
          setLoading(false);
        });
    }, [dataProvider, notify]);
  
    const handleAssign = async () => {
      try {
        const header = await Header.getHeader();
        await API.post("secure-api", "api/admin/tagassign", {
          headers: {
            ...header,
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
          },
          body: {
            user_ids: selectedIds,
            resource_id: tag.id,
          },
        });
        notify("Users updated successfully", "info");
        onUnselectItems();
        refresh();
      } catch (error) {
        notify("Error: Users not updated", "warning");
      }
    };
  
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign Tag</DialogTitle>
        <DialogContent>
          {loading ? (
            <p>Loading tags...</p>
          ) : (
            <FormControl fullWidth>
              <InputLabel id="campaign-select-label">Tag</InputLabel>
              <Select
                labelId="campaign-select-label"
                id="campaign-select"
                value={tag.id}
                onChange={(e) => {
                  const selectedTag = tags.find((c) => c.id === e.target.value);
                  setTag(selectedTag);
                }}
              >
                {tags.map((tagItem) => (
                  <MenuItem key={tagItem.id} value={tagItem.id}>
                    {tagItem.resource_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAssign} color="primary" disabled={loading}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  