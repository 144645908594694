import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  SelectInput,
  FormDataConsumer,
  ShowButton,
  SimpleShowLayout,
  FilterListItem,
  FilterList,
  Pagination,
  ReferenceManyField,
  Show,
  Button,
  useNotify,
  useListContext,
  useShowController,
  useRefresh,
  UrlField,
  useDataProvider,
  BooleanInput,
  FunctionField,
  useGetList,
} from "react-admin";
import { Create, Edit, SimpleForm, TextInput, ReferenceInput } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { Card, CardContent } from "@material-ui/core";
import Header from "../utils/auth/getHeader";
import { API } from "aws-amplify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
  Button as MuiButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import { AssignFlag, AssignTag } from "./AssignTagFlag";



const BulkActionButtonAccept = ({activity_id }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();
  // Action for button one
  const handleClick = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/activitystatus", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          activity_status: 'approved',
          user_ids: selectedIds,
          resource_id: activity_id
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };
  return <Button label="Approve" onClick={handleClick} />;
};



const BulkActionReject = ({ activity_id  } ) => {
  const [open, setOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeStatus = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/activitystatus", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          activity_status: 'rejected',
          status_note: rejectionReason,
          user_ids: selectedIds,
          resource_id: activity_id,
        },
      });
      notify("Activity Status Changed", "info");
      handleClose();
      onUnselectItems();
      refresh();
    } catch (error) {
      notify("Error", "warning");
    }
  };

  return (
    <>
      <Button label="Reject" onClick={handleOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reason</DialogTitle>
        <DialogContent>
          <MuiTextField
            label="Reason"
            type="text"
            fullWidth
            value={rejectionReason}
            onChange={(event) => setRejectionReason(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose}>Cancel</MuiButton>
          <MuiButton onClick={changeStatus}>Submit</MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};



// Email Dialog Component
const BulkActionSendEmail = ({ activity_id, initial_emailBody, initial_emailSubject }) => {
  const [open, setOpen] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();


  const handleOpen = () => {
    setEmailSubject(initial_emailSubject || "");
    setEmailBody(initial_emailBody || "");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmail = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/sendemail", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          email_body: emailBody,
          email_subject: emailSubject,
          user_ids: selectedIds,
          resource_id: activity_id,
        },
      });
      notify("Email sent successfully", "info");
      handleClose();
      onUnselectItems();
      refresh();
    } catch (error) {
      notify("Error: Email not sent", "warning");
    }
  };

  return (
    <>
      <Button label="Send Email" onClick={handleOpen} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent>
          <MuiTextField
            label="Email Subject"
            type="text"
            fullWidth
            value={emailSubject}
            onChange={(event) => setEmailSubject(event.target.value)}
          />
        </DialogContent>
        <DialogContent>
          <MuiTextField
            label="Email Body"
            type="text"
            fullWidth
            value={emailBody}
            onChange={(event) => setEmailBody(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose}>Cancel</MuiButton>
          <MuiButton onClick={handleSendEmail}>Send Email</MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AssignCampaignModal = ({ open, handleClose }) => {
  const [campaign, setCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();
  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("campaign", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setCampaigns(data);
      })
      .catch((error) => {
        notify("Error loading campaigns: " + error.message, "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]); // Dependencies for useEffect.

  const handleAssign = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignassign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
          campaign_id: campaign.id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      // Handle error case
      notify("Error: Users not updated", "warning");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Invite to Campaign</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading campaigns...</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="campaign-select-label">Campaign</InputLabel>
            <Select
              labelId="campaign-select-label"
              id="campaign-select"
              value={campaign.id} // Modified to use campaign.id
              onChange={(e) => {
                // Find the selected campaign object and set it
                const selectedCampaign = campaigns.find(
                  (c) => c.id === e.target.value
                );
                setCampaign(selectedCampaign);
              }}
            >
              {campaigns.map((campaignItem) => (
                <MenuItem key={campaignItem.id} value={campaignItem.id}>
                  {campaignItem.resource_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssign} color="primary" disabled={loading}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Step 3: Custom BulkActionButtons component
const CustomBulkActions = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [flagModalOpen, setFlagModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}>Add To Campaign</Button>
      <AssignCampaignModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
      <Button onClick={() => setFlagModalOpen(true)}>Flag User</Button>
      <AssignFlag
        open={flagModalOpen}
        handleClose={() => setFlagModalOpen(false)}
      />
      <Button onClick={() => setTagModalOpen(true)}>Tag User</Button>
      <AssignTag
        open={tagModalOpen}
        handleClose={() => setTagModalOpen(false)}
      />
    </>
  );
};



const BulkActionSendSms = ({ activity_id, initial_smsText }) => {
  const [open, setOpen] = useState(false);
  const [smsText, setSmsText] = useState(initial_smsText); // This should be set to your SMS.invitation field value
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();

  // Function to open the modal dialog
  const handleOpen = () => {
    // Here you would fetch and set the default message from your `SMS.invitation` field
    // setSmsText('Your default SMS invitation text');
    setOpen(true);
  };

  // Function to close the modal dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Function to send SMS
  const handleSend = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/sendsms", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          sms_text: smsText,
          user_ids: selectedIds,
          resource_id: activity_id,
        },
      });
      notify("SMS sent successfully", "info");
      handleClose();
      onUnselectItems();
      refresh();
    } catch (error) {
      notify("Error: SMS not sent", "warning");
    }
  };

  return (
    <>
      <Button label="Send SMS" onClick={handleOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
        <DialogContent>
          <MuiTextField
            autoFocus
            margin="dense"
            id="smsText"
            label="SMS Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={smsText}
            onChange={(event) => setSmsText(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose} color="primary">
            Cancel
          </MuiButton>
          <MuiButton onClick={handleSend} color="primary">
            Send
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};


export const ActivityList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="resource_name" label="Activity" />{" "}
        <EditButton basePath="/activity" />
        <ShowButton basePath="/activity" />
      </Datagrid>
    </List>
  );
};

export const ActivityEdit = (props) => {
  return (
    <Edit title="Edit Activity" {...props}>
      <SimpleForm defaultValue={{
        disclose: true, // Set default value for disclose field
      }}
      >
        <TextInput source="resource_name" label="Activity" />
        <SelectInput
          source="activity_status"
          label="Activity Status"
          choices={[
            { id: "active", name: "Active" },
            { id: "draft", name: "Draft" },
            { id: "closed", name: "Closed" },
            { id: "past", name: "Past" },
          ]}
        />
        <TextInput source="type" label="Activity Type" disabled />

        <RichTextInput
          source="description"
          label="Description (text in list of activites)"
        />
        <RichTextInput
          source="instructions"
          label="Instructions (Text in activity detail)"
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === "tiktok_publish" && (
              <>
                <BooleanInput
                  source="show_when_assigned"
                  label="Show Only When Assigned"
                />{" "}
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === "review" && (
              <>
                <TextInput source="review_site" label="Review Site" />
                <TextInput source="review_link" label="Review Link" />
                <TextInput source="review_site2" label="Review Site 2" />
                <TextInput source="review_link2" label="Review Link 2" />
                <BooleanInput source="disclose" label="Add disclose text" defaultValue={true} />
                <TextInput label="Words to Avoid" source="avoid_words" />
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            (formData.type === "tiktok_publish" ||  formData.type === "raw_video" ) && (
              <>
                <TextInput source="hashtags" label="Hashtags" />{" "}
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) =>
            formData.type === "raw_video" && (
              <>
                <TextInput source="sample_video_raw" label="Raw Video Link" />
                <TextInput source="sample_video_test" label="Test Video Link" />
              </>
            )
          }
        </FormDataConsumer>
        <TextInput source="SMS.invitation" label="SMS Text" />
        <TextInput source="reward" label="Reward Amount" />
        <TextInput source="EMAIL.invitation.subject" label="Email Subject" />
        <RichTextInput source="EMAIL.invitation.body" label="Email Body" />
        <TextInput label="Required Activity ID" source="required_activity_id" />
        <TextInput label="Required Activity Error Message" source="required_activity_error" />
      </SimpleForm>
    </Edit>
  );
};


export const ActivityCreate = (props) => {
  return (
    <Create title="Create Activity" {...props}>
      <SimpleForm>
        <ReferenceInput
          label="Campaign"
          source="parent_id"
          reference="campaign"
        >
          <SelectInput optionText="resource_name" />
        </ReferenceInput>
        <TextInput source="resource_name" label="Activity" />
        <SelectInput
          source="activity_status"
          label="Activity Status"
          choices={[
            { id: "active", name: "Active" },
            { id: "draft", name: "Draft" },
            { id: "closed", name: "Closed" },
            { id: "past", name: "Past" },
            // ... add more types as needed
          ]}
        />
        <SelectInput
          source="type"
          label="Activity Type"
          choices={[
            { id: "review", name: "Text Review" },
            { id: "raw_video", name: "TikTok Raw Video" },
            { id: "tiktok_post", name: "TikTok Post" },
            { id: "tiktok_publish", name: "TikTok Publish" },
            { id: "url_submit", name: "Url Submit" },
            { id: "image_upload", name: "Image Upload" },
            { id: "selfi_upload", name: "Selfie Upload" }
            // ... add more types as needed
          ]}
        />
      </SimpleForm>
    </Create>
  );
};



const Aside = () => {

  const { data: tags, isLoading } = useGetList('tag', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'resource_name', order: 'ASC' },
    filter: {}
  });

  return (
  <div style={{ width: 200, margin: "1em" }}>
    <FilterList label="Activity Status">
      <FilterListItem
        label="Not Started"
        value={{ user_activity_status: "not_started" }}
      />
      <FilterListItem
        label="Assigned"
        value={{ user_activity_status: "assigned" }}
      />
      <FilterListItem
        label="Completed"
        value={{ user_activity_status: "completed" }}
      />
      <FilterListItem
        label="Approved"
        value={{ user_activity_status: "approved" }}
      />
      <FilterListItem
        label="Rejected"
        value={{ user_activity_status: "rejected" }}
      />
    </FilterList>
    <FilterList label="Gender">
      <FilterListItem label="Female" value={{ gender: "Female" }} />
      <FilterListItem label="Male" value={{ gender: "Male" }} />
      <FilterListItem label="Other" value={{ gender: "Other" }} />
      <FilterListItem label="Decline" value={{ gender: "Decline" }} />
    </FilterList>
    <FilterList label="Min Age">
      <FilterListItem
        label="25"
        value={{
          min_age: (new Date().getFullYear() - 25).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="30"
        value={{
          min_age: (new Date().getFullYear() - 30).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="35"
        value={{
          min_age: (new Date().getFullYear() - 35).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="40"
        value={{
          min_age: (new Date().getFullYear() - 40).toString() + "-00-00",
        }}
      />
    </FilterList>
    <FilterList label="Max Age">
      <FilterListItem
        label="30"
        value={{
          max_age: (new Date().getFullYear() - 30).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="35"
        value={{
          max_age: (new Date().getFullYear() - 35).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="40"
        value={{
          max_age: (new Date().getFullYear() - 40).toString() + "-00-00",
        }}
      />
      <FilterListItem
        label="45"
        value={{
          max_age: (new Date().getFullYear() - 45).toString() + "-00-00",
        }}
      />
    </FilterList>
    <FilterList label="Min TikTok Followers">
      <FilterListItem label="100" value={{ min_follower_count: 100 }} />
      <FilterListItem label="500" value={{ min_follower_count: 500 }} />
      <FilterListItem label="1000" value={{ min_follower_count: 1000 }} />
    </FilterList>
    <FilterList label="Show Flagged">
        <FilterListItem label="Yes" value={{ flagged: true }} />
      </FilterList>
    <FilterList label="Tags">
        {!isLoading && tags
          ? tags.map(tag => (
            <FilterListItem
              key={tag.id}
              label={tag.resource_name || 'Unnamed Flag'} // Fallback to a default string if `flag.name` is not a string
              value={{ tag: tag.id + '+' + tag.resource_name || 'unnamed_flag' }} // Ensure the value is also a valid string
            />
          ))
          : <div>Loading...</div>}
      </FilterList>
  </div>
  )
};


const ImageOrVideoPlayerField = ({ record = {} }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContentType, setModalContentType] = useState(null); // 'image' or 'video'

  const fileUrl = record['file_url'];
  const fileName = record['files']?.[record['files'].length - 1];

  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const fileExtension = fileName?.split('.').pop().toLowerCase();

  if (!fileUrl || !fileExtension) {
    return null;
  }

  const handleOpenModal = (contentType) => {
    setModalContentType(contentType);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContentType(null);
  };

  return (
    <>
      {/* Thumbnail rendering */}
      {imageExtensions.includes(fileExtension) ? (
        <img
          src={fileUrl}
          alt="Thumbnail"
          style={{ cursor: 'pointer', maxWidth: '240px', maxHeight: '320px' }}
          onClick={() => handleOpenModal('image')}
        />
      ) : (
        <video
          width="240"
          height="320"
          style={{ cursor: 'pointer' }}
          onClick={() => handleOpenModal('video')}
        >
          <source src={fileUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {/* Modal */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent style={{ position: 'relative', textAlign: 'center', padding: 0 }}>
          <IconButton
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* Modal content */}
          {modalContentType === 'image' ? (
            <img
              src={fileUrl}
              alt="Full size"
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '90vh',
                objectFit: 'contain',
              }}
            />
          ) : (
            <video
              width="100%"
              height="100%"
              controls
              style={{
                maxWidth: '100%',
                maxHeight: '90vh',
              }}
            >
              <source src={fileUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};



const UserGrid = ({bulkActionButtons, activity_type}) => (
  <Datagrid bulkActionButtons={bulkActionButtons}>
    <TextField source="pk" label="UID" />
    <TextField source="first_name" label="First Name" />
    <TextField source="last_name" label="Last Name" />
    <TextField source="user_activity_status" label="Activity Status" />
    <TextField source="status_note" label="Last Status Note" />
    <TextField source="stars" label="Review Stars" />
    <TextField source="review_text" label="Review Text" />
    <TextField source="last_sms_time" label="Last SMS Time" />
    <TextField source="last_email_time" label="Last Email Time" />
    <TextField source="date_created" label="Activity Completed" />
    <TextField
      source="social_tiktok.tiktok_user_info.data.user.follower_count"
      label="Follower#"
    />
      <UrlField
      source="tiktok_url"
      label="TikTok Link"
    />
    {/* <TextField label="no_share" source="no_partner_sharing" />
    <TextField label="unsub" source="unsubscribe" /> */}
    
    { (activity_type==='raw_video' || activity_type==='image_upload' || activity_type=== "selfi_upload" ) && 
    (<FunctionField label="Video" render={(record) => <ImageOrVideoPlayerField record={record} />} />)
    }
  
    <EditButton basePath="/activityuser" />
    {/*<ShowButton basePath="/user" />*/}
  </Datagrid>
);

const ActivityUserPagination = () => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 300]} />
);



export const ActivityShow = (props) => {
  const controllerProps = useShowController(props);
  const { record } = controllerProps;
  const activity_id = record ? record.id : null;
  const [smsText, setSmsText] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [activityType, setActivityType] = useState("");

  useEffect(() => {
    if (record) {
      setSmsText(record.SMS?.invitation || "");
      setEmailSubject(record.EMAIL?.invitation?.subject || "");
      setEmailBody(record.EMAIL?.invitation?.body || "");
      setActivityType(record.type || "");
    }
  }, [record]);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="resource_name" label="Activity Name" />
        <TextField source="SMS.invitation" label="SMS Text" />
        <TextField source="EMAIL.invitation.subject" label="Email Subject" />
        <TextField source="EMAIL.invitation.body" label="Email Body" />
        <ReferenceManyField
          label="Users in Activity"
          reference="activityuser"
          target="activity_id"
          pagination={null}
        >
          <Card>
            <CardContent style={{ display: "flex", paddingTop: "50px" }}>
              <Aside />
              <UserGrid
                activity_type={activityType}
                bulkActionButtons={
                  <>
                    <BulkActionReject  activity_id = {activity_id} />
                    <BulkActionButtonAccept  activity_id = {activity_id} />
                    <CustomBulkActions />
                    <BulkActionSendSms
                      activity_id={activity_id}
                      initial_smsText={smsText}
                    />
                    <BulkActionSendEmail
                      activity_id={activity_id}
                      initial_emailBody={emailBody}
                      initial_emailSubject={emailSubject}
                    />
                  </>
                }
              />
            </CardContent>
          </Card>
          <ActivityUserPagination />
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};


export const ActivityUserShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="resource_name" label="Activity Name" />
      </SimpleShowLayout>
    </Show>);
};
