// components/BulkActionButtonInvite.js

import React from "react";
import { Button, useNotify, useListContext, useRefresh } from "react-admin";
import { API } from "aws-amplify";
import Header from "../utils/auth/getHeader";

const BulkActionButtonCampaignInvite = ({ campaign_id }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();

  const handleClick = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignstatus", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          campaign_status: "invited",
          user_ids: selectedIds,
          campaign_id: campaign_id, // Adding the campaign_id to the payload
        },
      });
      notify("Users updated successfully", "info");
      onUnselectItems();
      refresh();
    } catch (error) {
      notify("Error: Users not updated", "warning");
    }
  };

  return <Button label="Invite" onClick={handleClick} />;
};

export default BulkActionButtonCampaignInvite;
