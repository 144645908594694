import React from 'react';
import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

const DisplayNameOrUsernameField = () => {
    const record = useRecordContext();
    if (!record) return null;

    const displayName = record?.social_tiktok?.tiktok_user_info?.data?.user?.display_name;
    const username = record?.social_tiktok?.tiktok_user_info?.data?.user?.username;


    if (username) {
        const url = `https://www.tiktok.com/@${username}`;
        return (
            <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
            </a>
        );
    }
    
    if (displayName !== undefined) {
        return <Typography>{displayName}</Typography>;
    }

    

    return <Typography>N/A</Typography>;
};

export default DisplayNameOrUsernameField;
