// components/UserGrid.js

import React from "react";
import { Datagrid, TextField, UrlField } from "react-admin";

const UserGrid = (props) => (
  <Datagrid {...props}>
    <TextField source="first_name" label="First Name" />
    <TextField source="last_name" label="Last Name" />
    <TextField source="wallet" label="Wallet" />
    <TextField source="campaign_status" label="Campaign Status" />
    <TextField source="last_sms_time" label="Last SMS Time" />
    <TextField source="credit" label="Points" />
    <TextField source="gender" label="Gender" />
    <TextField source="date_created" label="Date Joined" />
    <TextField
      source="social_tiktok.tiktok_user_info.data.user.follower_count"
      label="Follower#"
    />
    <UrlField source="tiktok_url" label="TikTok Link" />
    <TextField source="cognito_birthdate" label="Birthdate" />
    <TextField label="no_share" source="no_partner_sharing" />
    <TextField label="unsub" source="unsubscribe" />
    <TextField label="tags" source="tags" />
    <TextField label="flags" source="flags" />
    {/* Uncomment or add additional fields as needed */}
    {/* <TextField source="address" label="Address" /> */}
    {/*<EditButton basePath="/user" />
    <ShowButton basePath="/user" />*/}
  </Datagrid>
);

export default UserGrid;
