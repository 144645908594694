// components/AssignCampaignModal.js

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useDataProvider, useNotify, useListContext, useRefresh } from "react-admin";
import { API } from "aws-amplify";
import Header from "../utils/auth/getHeader";

const BulkActionButtonAssignToCampaign = ({ open, handleClose }) => {
  const [campaign, setCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { selectedIds, onUnselectItems } = useListContext();

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList("campaign", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "resource_name" },
        filter: {},
      })
      .then(({ data }) => {
        setCampaigns(data);
      })
      .catch((error) => {
        notify("Error loading campaigns: " + error.message, "warning");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]);

  const handleAssign = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/campaignassign", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          user_ids: selectedIds,
          campaign_id: campaign.id,
        },
      });
      notify("Users assigned to campaign successfully", "info");
      onUnselectItems();
      refresh();
      handleClose();
    } catch (error) {
      notify("Error: Users not assigned to campaign", "warning");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Assign to Campaign</DialogTitle>
      <DialogContent>
        {loading ? (
          <p>Loading campaigns...</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel id="campaign-select-label">Campaign</InputLabel>
            <Select
              labelId="campaign-select-label"
              id="campaign-select"
              value={campaign.id || ""}
              onChange={(e) => {
                const selectedCampaign = campaigns.find(
                  (c) => c.id === e.target.value
                );
                setCampaign(selectedCampaign);
              }}
            >
              {campaigns.map((campaignItem) => (
                <MenuItem key={campaignItem.id} value={campaignItem.id}>
                  {campaignItem.resource_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAssign} color="primary" disabled={loading || !campaign}>
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkActionButtonAssignToCampaign;

