// components/BulkActionSendSms.js

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
} from "@material-ui/core";
import { useNotify, useListContext, useRefresh } from "react-admin";
import { API } from "aws-amplify";
import Header from "../utils/auth/getHeader";

const BulkActionSendSms = ({ campaign_id, initial_smsText }) => {
  const [open, setOpen] = useState(false);
  const [smsText, setSmsText] = useState(initial_smsText); // This should be set to your SMS.invitation field value
  const { selectedIds, onUnselectItems } = useListContext();
  const notify = useNotify();
  const refresh = useRefresh();

  // Function to open the modal dialog
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to close the modal dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Function to send SMS
  const handleSend = async () => {
    try {
      const header = await Header.getHeader();
      await API.post("secure-api", "api/admin/sendsms", {
        headers: {
          ...header,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          sms_text: smsText,
          user_ids: selectedIds,
          resource_id: campaign_id,
        },
      });
      notify("SMS sent successfully", "info");
      handleClose();
      onUnselectItems();
      refresh();
    } catch (error) {
      notify("Error: SMS not sent", "warning");
    }
  };

  return (
    <>
      <Button label="Send SMS" onClick={handleOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Send SMS</DialogTitle>
        <DialogContent>
          <MuiTextField
            autoFocus
            margin="dense"
            id="smsText"
            label="SMS Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={smsText}
            onChange={(event) => setSmsText(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSend} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkActionSendSms;
